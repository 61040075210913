@import url('https://fonts.googleapis.com/css2?family=Cutive+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cutive+Mono&family=Special+Elite&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cutive+Mono&family=Sedgwick+Ave+Display&family=Special+Elite&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Bungee+Inline&family=Bungee+Shade&family=Cutive+Mono&family=Limelight&family=Monoton&family=Sedgwick+Ave+Display&family=Special+Elite&family=Zilla+Slab&family=Zilla+Slab+Highlight&display=swap');
/*Botones*/
@import url('https://fonts.googleapis.com/css2?family=Cutive+Mono&family=Limelight&family=Sedgwick+Ave+Display&family=Special+Elite&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cutive+Mono&family=Limelight&family=Monoton&family=Sedgwick+Ave+Display&family=Special+Elite&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bungee+Inline&family=Cutive+Mono&family=Limelight&family=Monoton&family=Sedgwick+Ave+Display&family=Special+Elite&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bungee+Inline&family=Bungee+Shade&family=Cutive+Mono&family=Limelight&family=Monoton&family=Sedgwick+Ave+Display&family=Special+Elite&display=swap');

@font-face {
    font-family: 'Berlin'; /* Nombre que quieras asignar a tu fuente */
    src: url('../src/fonts/Berlin.ttf') format('truetype'); /* Ruta a tu archivo de fuente TTF */
}
@font-face {
    font-family: 'Mean'; /* Nombre que quieras asignar a tu fuente */
    src: url('../src/fonts/Mean.ttf') format('truetype'); /* Ruta a tu archivo de fuente TTF */
}
@font-face {
    font-family: 'Elite'; /* Nombre que quieras asignar a tu fuente */
    src: url('../src/fonts/Elite.ttf') format('truetype'); /* Ruta a tu archivo de fuente TTF */
}
@font-face {
    font-family: 'Bangers'; /* Nombre que quieras asignar a tu fuente */
    src: url('../src/fonts/Bangers.ttf') format('truetype'); /* Ruta a tu archivo de fuente TTF */
}
@font-face {
    font-family: 'Fjalla'; /* Nombre que quieras asignar a tu fuente */
    src: url('../src/fonts/Fjalla.ttf') format('truetype'); /* Ruta a tu archivo de fuente TTF */
}
/*
    font-family: 'Special Elite';
    font-family: 'Mean';
    font-family: 'Monoton';
    font-family: 'Bungee Shade';
*/

/* Evitar que elementos no interactivos reciban el foco visual */
*:focus {
    outline: none;
    cursor: default;
}

/* Específicamente permitir foco visual en inputs, textarea y elementos interactivos */
input:focus, textarea:focus, button:focus, a:focus {
    outline: auto;
    cursor: pointer;
}


body {
    margin: 0;
    font-family: 'Fjalla';
    /*-webkit-font-smoothing: antialiased;*/
    -moz-osx-font-smoothing: grayscale;
    background-image: url('../src/images/background.png'); /* Ruta a tu imagen de fondo */
    background-size: cover; /* Ajusta la imagen para cubrir todo el fondo */
    background-position: center; /* Centra la imagen en el fondo */
    background-repeat: no-repeat; /* Evita la repetición de la imagen */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


html, body {
}
a{
    text-decoration:none;
    color:white;
}



button:hover {
    transform: scale(1.1); /* Aumenta ligeramente el tamaño */
}

.container {
    text-align: center;
    position: relative;
    height: 100vh;
    width: 100vw;
}


/*HOME*/


.home{
    width:100vw;
    height:100vh;
}
.user-info {
    position: absolute;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    top: 30px;
    left: 50px;
    font-family: 'Fjalla';
}
    .user-info:hover {
        transform: scale(1.05); /* Aumenta ligeramente el tamaño */
        box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
    }

.user-info .user-details{
    position: relative;
}

.notify-image {
    position: absolute;
    width: 45px;
    height: 38px;
    top: -17px;
    left: -17px;
}
.notify-gang-image {
    position: absolute;
    width: 35px;
    height: 30px;
    top: 0px;
    left: 03px;
}
.user-image {
  width: 160px;
  height: 160px;
  object-fit:cover;
  border-radius: 80px;
  border: solid 3px #ffffff;
  z-index:1;
}

.user-text {
    width: 300px;
    text-align: left;
    color: black;
    position: absolute;
    bottom: 51px;
    left: 115px;
    background-color: white;
    padding: 10px 50px 10px 50px;
    border-radius: 34px;
    z-index: -5;
    font-weight: bold;
}
.home-top {
    position: absolute;
    top: 60px;
    right:0px;
    display: flex;
    align-items: end;
    justify-content: end;
    padding: 0px 100px 0px 100px;
}
.button-container {
    position: absolute;
    bottom: 80px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 100px 0px 100px;
}

.home button {
    padding: 20px;
    border: none;
    border-radius: 15px;
    background-color: rgba(242, 183, 204, 0.9);
    border: solid 4px #e555bc;
    cursor: pointer;
    color: white;
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Transiciones para suavizar el agrandamiento y la sombra */
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    font-family: 'Monoton';
    font-size: 45px;
}
.home button:hover{
  background-color: rgba(0, 0, 0, 0.7); /* Gris semitransparente más oscuro al pasar el cursor */
  transform: scale(1.1);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}

.corner-button {
    position:relative;
    width: 330px;
    height: 100px;
    line-height: 20px;
}
.arch{
    width: 400px;
}
.button-container .main-button {
    font-family: 'Monoton';
    font-size: 50px;
    width: 220px;
    height: 220px;
    border-radius: 110px;
}

@media (max-width: 1250px){
    .home .button-container {
        bottom: 30px;
        padding: 0px 50px 0px 50px;
    }
    .home-top {
        padding: 0px 50px 0px 50px;
    }

}


@media (max-width: 1050px) {
    .home button {
        font-size: 29px;
    }
    .corner-button {
        width: 270px;
        height: 80px;
    }
    .button-container .main-button {
        font-family: 'Monoton';
        font-size: 40px;
        width: 190px;
        height: 190px;
        border-radius: 95px;
    }
}

@media (max-width: 1000px) {
    .home button {
        font-size: 25px;
    }

    .corner-button {
        width: 210px;
        height: 65px;
    }
    .arch {
        width: 240px;
    }

    .button-container .main-button {
        font-family: 'Monoton';
        font-size: 30px;
        width: 160px;
        height: 160px;
        border-radius: 80px;
    }
    .user-image {
        width: 120px;
        height: 120px;
        border-radius: 60px;
    }
    .user-text {
        width: 260px;
        bottom: 27px;
        font-size: 14px;
        left: 78px;
        border-radius: 31px;
    }
}



/*TEST*/




.btn-test-container {
    position: absolute;
    width: 100%;
    bottom: 25px;
    left: 0px;
    display: flex;
    justify-content: space-between;
    padding-right: 50px;
    padding-left: 50px;
    z-index: 2;
    min-height: 90px;
}
.btn-second-test-container {
    position: absolute;
    width: 50%;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: space-between;
    padding-right: 20px;
    padding-left: 20px;
    z-index: 4;
}


.slider-container {
    background-image: url('../src/images/fondo-test.png'); /* Ruta a tu imagen de fondo */
    background-size: 100% 100%; /* Ajusta la imagen para cubrir todo el fondo */
    background-position: center; /* Centra la imagen en el fondo */
    background-repeat: no-repeat; /* Evita la repetición de la imagen */
    position: relative;
}


.test-container {
    font-family: Mean;
    height: 100vh;
    backdrop-filter: blur(5px);
    display:flex;
    align-items:center;
    justify-content:center;
    padding-left:60px;
    padding-right:60px;
    position: relative;
}
.winegangs-container {
    position: relative;
    height: 100vh;
    width: 100vw;
    min-height: 415px;
    font-family: 'Fjalla';
}
.slider-container {
    width: 1000px;
    height: 640px;
    padding: 50px;
    margin: 0 auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
@media (max-height: 760px) {
    .slider-container {
        height: 85%;
        padding: 25px;
    }
}
@media (max-height: 580px) {
    .slider-container {
        height: 460px;
    }
}
@media (max-height: 620px) {
    .slider-container {
        height: 100%;
        min-height:410px;
    }
}
    .slider-container .section-container {
        background-image: url('../src/images/test-section-container.png'); /* Ruta a tu imagen de fondo */
        background-size: 100% 100%; /* Ajusta la imagen para cubrir todo el fondo */
        background-position: center; /* Centra la imagen en el fondo */
        background-repeat: no-repeat;
        max-width: fit-content;
        margin: auto;
        display: flex;
        justify-content: space-between;
        padding:15px;
        padding-top:30px;
        position:relative;
        margin-top:30px;
    }
    .slider-container .sub-section-container {
        background-image: url('../src/images/square-section-container.png'); /* Ruta a tu imagen de fondo */
        background-size: 100% 100%; /* Ajusta la imagen para cubrir todo el fondo */
        background-position: center; /* Centra la imagen en el fondo */
        background-repeat: no-repeat;
        margin: auto;
        display: flex;
        flex-wrap: wrap;
        padding: 5px;
        padding-top: 15px;
        font-size:12px;
        position: relative;
        margin-top: 30px;
    }
        .slider-container .section-container .section-title {
            position: absolute;
            background-image: url('../src/images/test-section-title.png'); /* Ruta a tu imagen de fondo */
            background-size: 100% 100%; /* Ajusta la imagen para cubrir todo el fondo */
            background-position: center; /* Centra la imagen en el fondo */
            background-repeat: no-repeat;
            left: 50%; /* Se posiciona en la mitad horizontal del contenedor principal */
            transform: translateX(-50%);
            top:-10px;
            padding:5px;
            font-weight: bold;
            text-align:center;
            width: 285px;
            
        }
.slider-container .sub-section-container .section-title {
    position: absolute;
    background-image: url('../src/images/test-section-title.png'); /* Ruta a tu imagen de fondo */
    background-size: 100% 100%; /* Ajusta la imagen para cubrir todo el fondo */
    background-position: center; /* Centra la imagen en el fondo */
    background-repeat: no-repeat;
    left: 50%; /* Se posiciona en la mitad horizontal del contenedor principal */
    transform: translateX(-50%);
    top: -10px;
    padding: 2px;
    width: 80%;
    font-weight: bold;
    text-align: center;
}
    .slider-container .sub-section-container button {
        width: calc(50% - 10px); /* Ancho del botón (50% del contenedor menos el margen) */
        margin: 5px;
        font-size: 11px;
        padding: 3px;
    }
    .slider-container .cont-sub-section {
        display: flex;
        margin-top: 30px;
    }
    .slider-container .col-sub-section {
        flex: 1; /* Hace que las columnas ocupen el mismo ancho */
        margin: 0 5px; /* Margen entre las columnas */
        max-width: calc(33.33% - 10px);
    }
    .slider-container .col-sub-section-2 {
        flex: 1; /* Hace que las columnas ocupen el mismo ancho */
        margin: 0 5px; /* Margen entre las columnas */
        max-width: calc(50% - 10px);
    }
        .slider-container h2 {
            margin-bottom: 20px;
            text-align:center;
            font-size: 45px;
            color: white;
            font-family: Bangers;
            text-shadow: 4px 4px 4px rgba(80, 33, 63, 1), /* Sombra con desplazamiento 4px hacia abajo y derecha, desenfoque 4px, color rgba negro con 50% de opacidad */
            -4px -4px 4px rgba(80, 33, 63, 1), /* Sombra con desplazamiento 4px hacia arriba y izquierda, desenfoque 4px, color rgba negro con 50% de opacidad */
            4px -4px 4px rgba(80, 33, 63, 1), /* Sombra con desplazamiento 4px hacia abajo y izquierda, desenfoque 4px, color rgba negro con 50% de opacidad */
            -4px 4px 4px rgba(80, 33, 63, 1); /* Sombra con desplazamiento 4px hacia arriba y derecha, desenfoque 2px, color rgba negro con 50% de opacidad */
        }

.page-content {
  /* Estilos para el contenido de cada página */
  padding: 20px;
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.btn-back, .btn-next {
    filter: drop-shadow(7px 7px 10px #000000);
    background-position: center top;
    background-repeat: no-repeat;
    height: 120px;
    width: 70px;
    font-size: 12px;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}
.safari .btn-back, .safari .btn-next{

filter:none;
}
.btn-back {
    background-image: url('../src/images/back-game.png');
    background-size: 60%;
}
.btn-next {
    background-image: url('../src/images/next-game.png');
    background-size: 110%;
    border: none;
}
    .btn-back:disabled{
    border:none;
}
.btn-inst, .btn-help {
    background-size: 70px;
    background-repeat: no-repeat;
    background-position: center bottom;
    height: 97px;
    width: 138px;
    font-size: 12px;
    color: black;
    display: flex;
    justify-content: flex-start;
}
.btn-answer .btn-help {
    width: 210px ;
}
.btn-second-test-container .btn span{
    width:100%;
}
.btn-inst {
    background-image: url('../src/images/instruc-btn.png');
}
.btn-help {
    background-image: url('../src/images/help-btn.png');
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Color de fondo transparente */
    backdrop-filter: blur(2px); /* Difuminado */
    z-index: 999; /* Z-index alto para cubrir el contenido anterior */
    display: none; /* Para alinear la imagen en la parte inferior */
}

    .overlay .centered-image {
        position: absolute;
        bottom: 0px; /* Ajusta la posición vertical de la imagen */
        width: 600px; /* Ajusta el ancho de la imagen */
        height: auto; /* Ajusta la altura de la imagen de forma automática */
        cursor: pointer; /* Cambia el cursor al pasar sobre la imagen */
        left: 50%;
        transform: translateX(-50%);
    }
    .overlay .dekstop {
        display: block;
    }
    .overlay .mobile {
        display: none;
    }



.sliders-container {
    position:relative;
}
    .sliders-container .taste-chart-container {
        max-width: 320px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
    
    .line {
        background-image: url('../src/images/medium-section-container.png'); /* Ruta a tu imagen de fondo */
        background-size: 100% 100%; /* Ajusta la imagen para cubrir todo el fondo */
        background-position: center; /* Centra la imagen en el fondo */
        background-repeat: no-repeat;
        width: 260px;; /* Ancho del botón (50% del contenedor menos el margen) */
        margin: 5px;
        display: flex; /* Utiliza flexbox para organizar los elementos */
        align-items: center;
        padding-right: 13px;
        padding-left: 9px;
        padding-top: 6px;
        position: absolute;
    }

    .line h4 {
        margin-right: 10px;
        font-size:17px;
    }
    .line .line-image{
        height:31px;
    }
.input-slider-container {
    margin-left: auto;
}
.sliders-container .line-e{
    top:177px;
    left:6px;
}
.sliders-container .line-d {
    left: 50%;
    transform: translateX(-50%);
    top: 310px;
}
.sliders-container .line-c {
    top: 177px;
    right: 6px;
}
.sliders-container .line-b {
    top: 0px;
    right: 0px;
}
.sliders-container .line-a {
    top: 0px;
    left: 0px;
}
.input-slider-container .input-info {
    font-size: 12px;
    text-align: center;
}
.numeric-input-slide{
  padding-left: 40px;
  height:100%;
}



.slider-container select {
    color: white;
    padding: 4px;
    width: 200px;
    background-color: #50213F;
    border: solid 2px black;
    border-radius: 6px;
}
.slider-container select option:hover {
    background-color: #50213F;
    color: #6e4f2c;
}
.text-note span {
    font-weight: bold;
    color: #8f1447;
}

.note-select-container {
    background-image: url('../src/images/medium-section-container.png'); 
    background-size: 100% 100%; 
    background-position: center; 
    background-repeat: no-repeat;
    width: 100%; 
    margin: 5px;
    align-items: center;
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 8px;
    padding-bottom: 8px;
}

    .note-select-container h4 {
        margin-right: 10px;
        font-size: 17px;
        margin-top: auto;
    }

.note-select-container select{
    margin-left: auto;
}
    .note-select-container div {
        margin-left: auto;
    }
.note-select-container .input-price-container{
    width: 80%;
}
    .note-select-container .input-price-container input {
        width: 100%;
    }


.slider0 .css-3g5hux-Slider {
    background-color: rgba(167, 149, 129, 1);
}
.slider2 .css-3g5hux-Slider {
    background-color: rgba(210, 164, 62, 1);
}
.slider3 .css-3g5hux-Slider {
    background-color: rgba(106, 16, 16, 1);
}
.slider4 .css-3g5hux-Slider {
    background-color: rgba(84, 34, 61, 1);
}
.slider5 .css-3g5hux-Slider {
    background-color: rgba(29, 154, 120, 1);
}
.input-slider-container .css-yvszuv-Slider {
    background-color: #547556;
    width:130px;
}

.input-slider-container .input-info {
    height:30px;
}


.wine-container .wine-bottle {
    border: none;
    filter: drop-shadow(7px 7px 10px #000000);
    background-size: 100% 100%;
    width: 60px;
    height: 220px;
    background-color: transparent;
    display: inline-block;
    margin-left: 17px;
    margin-right: 17px;
    position: relative;
}
.safari .wine-container .wine-bottle{
    filter: none;
}
    .wine-container .wine-bottle span {
        position: absolute;
        bottom: -25px;
        left: 50%;
        transform: translateX(-50%);
        color: black;
    }
.wine-container {
    background-image: url('../src/images/square-section-container.png'); /* Ruta a tu imagen de fondo */
    background-size: 100% 100%; /* Ajusta la imagen para cubrir todo el fondo */
    background-position: center; /* Centra la imagen en el fondo */
    background-repeat: no-repeat;
    padding: 15px;
    padding-bottom: 40px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    min-height: 300px;
}

    .wine-container .section-title {
        position: absolute;
        background-image: url('../src/images/test-section-title.png'); /* Ruta a tu imagen de fondo */
        background-size: 100% 100%; /* Ajusta la imagen para cubrir todo el fondo */
        background-position: center; /* Centra la imagen en el fondo */
        background-repeat: no-repeat;
        left: 50%; /* Se posiciona en la mitad horizontal del contenedor principal */
        transform: translateX(-50%);
        top: -10px;
        padding: 5px;
        width: 60%;
        font-size: 16px;
        text-align: center;
    }

    .wine-container .wine-order {
        width: 100%;
        bottom: 10px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        display: grid;
        grid-template-columns: repeat(4, 25%); /* Crea 4 columnas, cada una ocupando el 25% */
        justify-items: center;
        padding-right: 15px;
        padding-left: 15px;
    }
    .wine-order span{
        text-align: center;
    }
.slide-container .wine-bottle-A {
    background-image: url('../src/images/wine-a.png');
}
.slide-container .wine-bottle-B {
    background-image: url('../src/images/wine-b.png');
}
.slide-container .wine-bottle-C {
    background-image: url('../src/images/wine-c.png');
}
.slide-container .wine-bottle-D {
    background-image: url('../src/images/wine-d.png');
}
.slide-container .title-points {
    font-family: Mean;
    margin-left: 6px;
    font-size: 16px;
    color: black;
    text-shadow: none;
}
.wine-container-first .wine-bottle-A {
    position: absolute;
    left: 12%;
    transform: translateX(-50%);
}

.wine-container-first .wine-bottle-B {
    position: absolute;
    left: 37%;
    transform: translateX(-50%);
}

.wine-container-first .wine-bottle-C {
    position: absolute;
    left: 62%;
    transform: translateX(-50%);
}

.wine-container-first .wine-bottle-D {
    position: absolute;
    left: 87%;
    transform: translateX(-50%);
}
.cont-sub-section .wine-container-second {
    display: grid;
    grid-template-columns: repeat(4, 25%); /* Crea 4 columnas, cada una ocupando el 25% */
    justify-items: center;
}
.slider-container .delete-button {
    background-color: transparent;
    border: none;
    color: red;
    font-size: 30px;
    font-weight: bold;
    position: absolute;
    top: 0px;
    right: 2px;
}

.section-container .btn {
    width: 140px;
}
.btn-olfatory {
    background-color: #BFBFBF;
    color: black;
    margin: 5px;
    border: solid 1px;
}
    .btn-selected:hover {
        background-color: #50213F;
        color: white;
        margin: 5px;
        border: solid 1px;
    }
.btn-olfatory:hover {
    background-color: #e2d4dc;
    margin: 5px;
    border: solid 1px;
}
.btn-selected {
    background-color: #50213F;
    color: white;
    margin: 5px;
    border: solid 1px;
}

.slider-container .text-note {
    background-image: url('../src/images/square-section-container.png'); /* Ruta a tu imagen de fondo */
    background-size: 100% 100%; /* Ajusta la imagen para cubrir todo el fondo */
    background-position: center; /* Centra la imagen en el fondo */
    background-repeat: no-repeat;
    padding:15px;
    padding-top:30px;
    position: relative;
    font-size: 14px;
}
.note-chart-container {
    height: 120px;
    display: flex;
    justify-content: center;
}
/*Chrome*/
@media screen and (-webkit-min-device-pixel-ratio:0) {
    input[type='range'] {
        overflow: hidden;
        width: 80px;
        -webkit-appearance: none;
        background-color: #000;
        height: 12px;
        border: solid 1px black;
    }

        input[type='range']::-webkit-slider-runnable-track {
            height: 15px;
            -webkit-appearance: none;
            color: #fff;
            margin-top: -1px;
            background: #547556;
        }

        input[type='range']::-webkit-slider-thumb {
            width: 15px;
            -webkit-appearance: none;
            height: 15px;
            cursor: ew-resize;
            background: black;
            box-shadow: -180px 0 0 180px #50213F;
        }
}


.text-note .section-title {
    position: absolute;
    background-image: url('../src/images/test-section-title.png'); /* Ruta a tu imagen de fondo */
    background-size: 100% 100%; /* Ajusta la imagen para cubrir todo el fondo */
    background-position: center; /* Centra la imagen en el fondo */
    background-repeat: no-repeat;
    left: 50%; /* Se posiciona en la mitad horizontal del contenedor principal */
    transform: translateX(-50%);
    top: -10px;
    padding: 5px;
    width:60%;
    font-size:16px;
    text-align:center;
}









.login-container {
    width: 550px;
    padding: 45px;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto; /* Añadido para centrar horizontalmente */
    margin-top: 10vh; /* Añadido para centrar verticalmente */
    filter: drop-shadow(7px 7px 10px #000000);
    background-size: 100% 100%;
}

.login-form {
    width: 100%; /* Asegura que el formulario ocupe el 100% del contenedor */
    display: flex;
    flex-direction: column;
}

.input-container {
    margin-bottom: 10px;
}
.input-container label{
    width:37%;
}
.input-login {
    width: 60%;
    background-image: url('../src/images/textura-marron.jpg'); /* Ruta a tu imagen de fondo */
    border: none;
    box-shadow: 1px 1px 5px #000000;
    background-size: 100% 100%;
    border-radius: 3px;
    padding: 3px 10px 3px 10px;
}
.form-line {
    background-image: url('../src/images/test-section-title.png'); /* Ruta a tu imagen de fondo */
    background-size: 100% 100%; /* Ajusta la imagen para cubrir todo el fondo */
    background-position: center; /* Centra la imagen en el fondo */
    background-repeat: no-repeat;
    width: 100%; /* Ancho del botón (50% del contenedor menos el margen) */
    margin: 5px;
    display: flex; /* Utiliza flexbox para organizar los elementos */
    justify-content: space-between;
    padding: 10px;
    color: black;
}
    .form-line input {
        height:26px;
        width: 60%;
        background-color: rgba(80, 33, 63, 1);
        border: none;
        box-shadow: 1px 1px 5px #000000;
        background-size: 100% 100%;
        border-radius: 3px;
        padding: 3px 10px 3px 10px;
        color: white;
        font-size:13px;
    }
.register-container .form-line input{
    font-family: Fjalla;
}
.checkbox-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.forgot-password {
    text-align: right;
    margin-bottom: 10px;
}
.login-container a{
    color: black;
}




.register-container {
    max-width: 500px;
    padding: 55px;
    padding-bottom:75px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto; /* Añadido para centrar horizontalmente */
    margin-top: auto; /* Añadido para centrar verticalmente */
    background-image: url('../src/images/fondo-test.png'); /* Ruta a tu imagen de fondo */
    filter: drop-shadow(7px 7px 10px #000000);
    background-size: 100% 100%;
    font-family: Mean;
    position:relative;
}
    .register-container h2 {
        margin-bottom: 20px;
        font-size: 45px;
        color: white;
        font-family: Bangers;
        text-shadow: 4px 4px 4px rgba(80, 33, 63, 0.5), 4px -4px 4px rgba(80, 33, 63, 0.5), -4px 4px 4px rgba(80, 33, 63, 0.5);
    }
.register-container form{
    width:100%;
    padding-top:10px;
}
    .register-container .close-button {
        background: none;
        border: none;
        position: absolute;
        width: 40px;
        height: 40px;
        top: 0px;
        right: 0px;
        padding: 0px;
    }
    .register-container .close-button img{
        width: 100%;
        height: 100%;
    }
.form-line{
    width:100%;
}
.form-line label{
    width:120px;
}
.form-line input {
        width: 230px;
}
.register-container a{
    color: black;
}
.register-container button {
    float: right;
    margin-bottom: 1rem;
    margin-top: 0.7rem;
    border-radius: 0px;
    background-color: #fff;
    color: black;
    border: solid 2px #000;
}

.register-container .error {
    text-transform: none;
    width: 100%;
    font-size: 10px;
    margin-left: 10px;
    margin-bottom: 0rem;
    color: red;
}
.register-container .link{
    font-size: 10px;
    margin-left:10px;
    color: black !important;
}
.ranking-container {
    width: 650px;
    padding: 55px;
    padding-top: 30px;
    height: 100vh;
    min-height: 410px;
    margin: auto; /* Añadido para centrar horizontalmente */
    background-image: url('../src/images/ranking.png'); /* Ruta a tu imagen de fondo */
    background-size: 100% 100%; /* Ajusta la imagen para cubrir todo el fondo */
    background-position: center; /* Centra la imagen en el fondo */
    background-repeat: no-repeat; /* Evita la repetición de la imagen */
    color: white;
    border: 2px solid #ffffff;
    position: relative;
}


.ranking-first{
    text-align: center;
    padding-top: 1px;
}
.ranking-first img{
    max-width: 50%;
}

.ranking-second {
    text-align: center;
    padding-top: 40px;
}

    .ranking-second img {
        max-width: 50%;
    }

.ranking-third {
    text-align: center;
    padding-top: 40px;
}

    .ranking-third img {
        max-width: 50%;
    }
.ranking-container h2 {
    text-align: center;
    font-family: 'Monoton';
    font-size: 30px;
}


.button-mens {
    width: 160px;
    position: absolute;
    left: 40px;
    bottom: 0px;
    padding: 20px;
    background-color: transparent;
    border: none;
    background-image: url('../src/images/bluebutton.png'); /* Ruta a tu imagen de fondo */
    background-size: 100% 100%; /* Ajusta la imagen para cubrir todo el fondo */
    background-position: center; /* Centra la imagen en el fondo */
    background-repeat: no-repeat; /* Evita la repetición de la imagen */
    color: white;
}
.button-gen {
    width: 160px;
    position: absolute;
    right: 40px;
    bottom: 0px;
    padding: 20px;
    background-color: transparent;
    border: none;
    background-image: url('../src/images/bluebutton.png'); /* Ruta a tu imagen de fondo */
    background-size: 100% 100%; /* Ajusta la imagen para cubrir todo el fondo */
    background-position: center; /* Centra la imagen en el fondo */
    background-repeat: no-repeat; /* Evita la repetición de la imagen */
    color: white;
}

.ranking-buttons {
    position: absolute;
    top: 70px;
    right: -97px;
    transform: rotate(90deg);
    background-color: white;
    width: 180px;
    height: 35px;
    color: black;
    z-index: -1;
    padding: 0px;
    padding-top: 3px;
    border-top-right-radius: 15px;
    z-index:5;
}
.ranking-buttons a{
    color:black;
}
.ranking-buttons button{
    width: 45%;
    display:inline-block;
}
.rank-indi {
    background-color: #FA90E1;
    border-top-right-radius: 15px;
    border: none;
}
.rank-wine{
    background: none;
    border: none;
    cursor: pointer;
}
.rank-gangs {
    background-color: #FA90E1;
}

.rank-gangs .rank-indi {
    background-color: white;
}
/* Estilo base para la barra de desplazamiento */
::-webkit-scrollbar {
  width: 6px;
}

/* Estilo para la pista de la barra de desplazamiento */
::-webkit-scrollbar-track {
  background-color: transparent; /* Color de fondo de la pista */
}

/* Estilo para el pulgar de la barra de desplazamiento (la parte desplazable) */
::-webkit-scrollbar-thumb {
  background-color: #555; /* Color del pulgar */
  border-radius: 3px; /* Bordes redondeados del pulgar */
  border: 3px  transparent; /* Borde del pulgar */
}

/* Cambia el estilo al pasar el ratón sobre la barra de desplazamiento */
::-webkit-scrollbar-thumb:hover {
  background-color: #333; /* Cambia el color al pasar el ratón sobre el pulgar */
}


.body-grey {
    background-color: grey;
    position: relative;
    height: 100vh;
    width: 100vw;
}
.user-container {
  width: 650px;
  padding: 55px;
  height: 100vh;
  margin: auto; /* Añadido para centrar horizontalmente */
  background-image: url('../src/images/user-background.png'); /* Ruta a tu imagen de fondo */
  filter: drop-shadow(7px 7px 10px #000000);
  background-size: 100% 100%;
  position: relative;
  
}
.user-container .profile-image{
  height: 88vh;
  position: absolute;
  bottom: 0px;
  left: 50%; /* Se posiciona en la mitad horizontal del contenedor principal */
  transform: translateX(-50%);
}
.user-container .profile-image img{
  
  max-height: 100%;
}
.profile-info {
    width: 430px;
    height: 190px;
    position: absolute;
    bottom: 80px;
    left: 50%; /* Se posiciona en la mitad horizontal del contenedor principal */
    transform: translateX(-50%);
    background-image: url('../src/images/cartel.jpg');
    background-size: contain;
    background-repeat: round;
    color: white;
    padding-left: 20px;
    padding-right: 20px;
    font-family: 'Fjalla';
    text-transform: uppercase;
}
.profile-info h4{
  text-align: center;
  color: white;
  margin-top: 15px;;
}

.button-back {
    position: absolute;
    left: -150px;
    top: 40vh;
    background-color:transparent;
    border: none;
    max-width:120px;
}
.button-back img{
    width:100%;
}
.button-next {
    position: absolute;
    right: -150px;
    top: 40vh;
    background-color: transparent;
    border: none;
    max-width: 120px;
}

    .button-next img {
        width: 100%;
    }

.user-container .gender-buttons{
    position: absolute;
    left:-180px;
    top: 30px;
}
    .user-container .gender-buttons .inverted {
        filter: none;
        pointer-events: none; /* Impide que el botón reciba eventos de clic */
        cursor: default;
    }
    .user-container .gender-buttons button {
        border: none;
        background: transparent;
        padding: 0px;
        width: 70px;
        max-height: 95px;
        filter: invert(1);
    }
    .user-container .gender-buttons button img{
        height:120px;
    }
.user-container .button-left {
    min-width: 200px;
    position: absolute;
    left: -205px;
    top: 24vh;
    padding: 40px;
    ;
    background-color: transparent;
    border: none;
    background-image: url('../src/images/bluebutton.png'); /* Ruta a tu imagen de fondo */
    background-size: 100% 100%; /* Ajusta la imagen para cubrir todo el fondo */
    background-position: center; /* Centra la imagen en el fondo */
    background-repeat: no-repeat; /* Evita la repetición de la imagen */
    color: white;
    font-family: 'Mean';
    font-size: 16px;
}

.button-edit {
    width: 200px;
    position: absolute;
    right: -200px;
    top: 24vh;
    padding: 40px;
    background-color: transparent;
    border: none;
    background-image: url('../src/images/bluebutton.png'); /* Ruta a tu imagen de fondo */
    background-size: 100% 100%; /* Ajusta la imagen para cubrir todo el fondo */
    background-position: center; /* Centra la imagen en el fondo */
    background-repeat: no-repeat; /* Evita la repetición de la imagen */
    color: white;
    font-family: 'Mean';
    font-size: 16px;
}
.button-logout {
    width: 200px;
    position: absolute;
    left: calc(50% - 100px);
    top: 1vh;
    padding: 40px;
    background-color: transparent;
    border: none;
    background-image: url('../src/images/bluebutton.png'); /* Ruta a tu imagen de fondo */
    background-size: 100% 100%; /* Ajusta la imagen para cubrir todo el fondo */
    background-position: center; /* Centra la imagen en el fondo */
    background-repeat: no-repeat; /* Evita la repetición de la imagen */
    color: white;
    font-family: 'Mean';
    font-size: 16px;
}

.informacion {
    position: fixed;
    padding: 30px;
    width: 825px;
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%);
    background-image: url('../src/images/fond.png'); /* Ruta a tu imagen de fondo */
    background-size: 100% 100%; /* Ajusta la imagen para cubrir todo el fondo */
    background-position: center; /* Centra la imagen en el fondo */
    background-repeat: no-repeat; /* Evita la repetición de la imagen */
    font-family: 'Elite';
    font-size:14px;
}
    .informacion h3 {
        text-align: center;
        

    }
.button-close {
    width: 50px;
    height: 50px;
    position: absolute;
    right: 0px;
    top: 0px;
    background-color: transparent;
    border: none;
    background-image: url('../src/images/close.png'); /* Ruta a tu imagen de fondo */
    background-size: 100% 100%; /* Ajusta la imagen para cubrir todo el fondo */
    background-position: center; /* Centra la imagen en el fondo */
    background-repeat: no-repeat; /* Evita la repetición de la imagen */
    color: white;
}
.oculto {
    display: none;
}




.blur {
    position: absolute;
    height: 100vh; /* Ajusta la altura según sea necesario */
    width: 100vw;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    color: #fff;
    backdrop-filter: blur(5px);
   
}

.stats-content {
    max-width: 1100px;
    height: 700px;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow-y: auto;
    overflow-x: hidden;
    font-family: Mean;
    font-size:13px;
}
.stats-content .stats-close{
    width:40px;
    height:40px;
    position:absolute;
    top:0px;
    right:0px;
    background-color:transparent;
    border:none;
    padding:0;
}
    .stats-content .stats-close img{
        width: 100%;
        height: 100%;
    }
.stats-first {
    background-image: url('../src/images/fondo-stats.png');
    background-size: 100% 100%; 
    background-position: center; 
    background-repeat: no-repeat;
    padding-right:65px;
    padding-left: 65px;
}

    .stats-first .chart-container {
        padding: 70px;
        position: relative;
        max-width: 600px;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .stats-first .chart-container canvas {
        width:100%;
    }

.chart-container .chart-img {
    position: absolute;
    width: 60px;
    height: auto;
    transition: transform 0.3s;
}
.chart-img .toltip {
    padding: 8px;
    background-color: rgba(0 , 0 , 0 , 0.7);
    border-radius:5px;
    display: none;
    transform: translateX(40px);
    text-align:center;

}

    .chart-img:hover .toltip,
    .chart-img:focus .toltip {
        display: block;
        position:absolute;
    }

.chart-container .visual {
    left: 50%;
    transform: translateX(-50%);
    top:21px;
}
.chart-container .olfativa {
    right: 70px;
    top: 18%;
}
.chart-container .gusto {
    right: 15px;
    top: 55%;
}
.variedad .chart-img {
    height: 60px;
}
.chart-container .variedad {
    right: 158px;
    bottom: 86px;
}
.chart-container .pais {
    left: 158px;
    bottom: 84px;
}
.region .chart-img {
    height: 60px;
}
.chart-container .region {
    left: 15px;
    top: 55%;
}
.chart-container .precio {
    left: 74px;
    top: 18%;
    width: 50px;
}
.stats-first .first-buttons {
    width: 100%;
    display: flex;
    justify-content: space-around; /* Para distribuir los elementos equidistantes */
    align-items: center;
    margin-top: 20px;
    margin-bottom: -50px;
}
    .stats-first .first-buttons button {
        width: 160px;
        padding: 20px;
        background-color: transparent;
        border: none;
        background-image: url('../src/images/bluebutton.png'); /* Ruta a tu imagen de fondo */
        background-size: 100% 100%; /* Ajusta la imagen para cubrir todo el fondo */
        background-position: center; /* Centra la imagen en el fondo */
        background-repeat: no-repeat; /* Evita la repetición de la imagen */
        color: white;
        z-index: 1;
        filter: opacity(0.7);
    }
    .stats-first .first-buttons .selected-button {
        filter: opacity(1);
    }
.stats-first .dropdowns-container {
    width: 100%;
    display: flex;
    justify-content: space-around; /* Para distribuir los elementos equidistantes */
    align-items: center;
    margin-top: -10px;
}
    .stats-first .dropdowns-container .stats-dropdown {
        color: white;
        padding: 2px 20px 2px 20px;
        border-radius: 7px;
    }
.dropdown-menu .dropdown-item {
    text-align: center;
}
.dropdowns-container .first {
    background-color: #FB712A;
    border: solid 4px #FB712A;
}
.dropdowns-container .second {
    background-color: #03E669;
    border: solid 4px #03E669;
}
.stats-second {
    background-image: url('../src/images/fondo-stats2.png');
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
}
.stats-second .title{
    text-align:center;
    margin-top:40px;
}

    .stats-second .wines {
        width: 100%;
        display: flex;
        justify-content: space-around; /* Para distribuir los elementos equidistantes */
        align-items: center;
        padding:30px;
        padding-left:50px;
        position:relative;
    }
    .stats-second .wine-img{
        height: 200px;
        display: block
    }
    .stats-second .wine span {
        display: block;
        text-align:center;
        margin-top:5px;
    }
    .stats-second .wines .wines-title {
        position: absolute;
        top: 50%;
        left: 2px;
        transform: rotate(-90deg);
    }
.global-buttons-bh {
    width: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 5px;
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    z-index: 0;
}
.global-buttons-bh button {
    background-color: transparent;
    border: none;
    height: 70px;
    width:70px;
    padding:0px;
}
    
.global-buttons-bh button img {
    width:100%;
    height:100%
}

.icon-test{
    height:60px;
    position: absolute;
    top:25px;
    right:30px;
}
.icon-mobile{
    display:none;
}
.actual-wine {
    position: absolute;
    top: 25px;
    left: 50px;
    font-size: 40px;
    color: rgba(80, 33, 63, 1);
    font-family: Bangers;
    width: 30px;
}
.gang-button-container {
    display: flex;
    justify-content: space-around; /* Para distribuir los elementos equidistantes */
    align-items: center;
    flex-wrap: wrap;
    position: absolute;
    top: 200px;
    padding-left: 100px;
    padding-right: 100px;
    width:100%;
    max-width: 1000px;
    left: 50%; /* Se posiciona en la mitad horizontal del contenedor principal */
    transform: translateX(-50%);
}
    .gang-button-container button:hover, .gang-second-button-container button:hover {
        transform: scale(1.1); /* Aumenta ligeramente el tamaño */
        box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
        z-index:1;
    }
    .gang-button-container .gang-button {
        position: relative;
        width: 240px;
        padding: 20px;
        background-color: transparent;
        border: none;
        background-image: url('../src/images/gang-btn.png'); /* Ruta a tu imagen de fondo */
        background-size: 100% 100%; /* Ajusta la imagen para cubrir todo el fondo */
        background-position: center; /* Centra la imagen en el fondo */
        background-repeat: no-repeat; /* Evita la repetición de la imagen */
        color: white;
        margin-top: 60px;
        transition: transform 0.3s ease, box-shadow 0.3s ease;
    }
.gang-second-button-container {
    display: flex;
    justify-content: space-around; /* Para distribuir los elementos equidistantes */
    align-items: center;
    flex-wrap: wrap;
    position: absolute;
    top: 80vh;
    padding-left: 100px;
    padding-right: 100px;
    width: 100%;
    max-width: 1000px;
    left: 50%; /* Se posiciona en la mitad horizontal del contenedor principal */
    transform: translateX(-50%);
}
    .gang-second-button-container .gang-second-button {
        position:relative;
        width: 160px;
        padding: 20px;
        background-color: transparent;
        border: none;
        background-image: url('../src/images/bluebutton.png'); /* Ruta a tu imagen de fondo */
        background-size: 100% 100%; /* Ajusta la imagen para cubrir todo el fondo */
        background-position: center; /* Centra la imagen en el fondo */
        background-repeat: no-repeat; /* Evita la repetición de la imagen */
        color: white;
        transition: transform 0.3s ease, box-shadow 0.3s ease;
    }


.new-gang-container {
    width: min(80%, 700px);
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display:flex;
    flex-direction: column;
    align-items:center;
}
.new-gang-container form{
    width: 100%;
    text-align: center;
}
    .new-gang-container .error {
        background-color: rgba(255, 255, 255, 0.8);
        padding: 5px 15px;
    }
    .new-gang-container .name {
        width: 240px;
        padding: 20px;
        background-color: transparent;
        border: none;
        background-image: url('../src/images/gang-btn.png'); /* Ruta a tu imagen de fondo */
        background-size: 100% 100%; /* Ajusta la imagen para cubrir todo el fondo */
        background-position: center; /* Centra la imagen en el fondo */
        background-repeat: no-repeat; /* Evita la repetición de la imagen */
        color: white;
        margin-top: 60px;
        text-align: center;
        font-family: 'Monoton';
    }
    .new-gang-container .description {
        display: block;
        width: 100%;
        height:200px;
        padding: 20px;
        background-color: transparent;
        border: none;
        background-image: url('../src/images/new-gang.png'); /* Ruta a tu imagen de fondo */
        background-size: 100% 100%; /* Ajusta la imagen para cubrir todo el fondo */
        background-position: center; /* Centra la imagen en el fondo */
        background-repeat: no-repeat; /* Evita la repetición de la imagen */
        margin-top: 20px;
    }
    .new-gang-container .submit {
        width: 160px;
        padding: 20px;
        background-color: transparent;
        border: none;
        background-image: url('../src/images/bluebutton.png'); /* Ruta a tu imagen de fondo */
        background-size: 100% 100%; /* Ajusta la imagen para cubrir todo el fondo */
        background-position: center; /* Centra la imagen en el fondo */
        background-repeat: no-repeat; /* Evita la repetición de la imagen */
        color: white;
        transition: transform 0.3s ease, box-shadow 0.3s ease;
    }


.gang-container {
    width: 650px;
    padding: 15px;
    height: 100vh;
    margin: auto; /* Añadido para centrar horizontalmente */
    background-image: url('../src/images/ranking.png'); /* Ruta a tu imagen de fondo */
    background-size: 100% 100%; /* Ajusta la imagen para cubrir todo el fondo */
    background-position: center; /* Centra la imagen en el fondo */
    background-repeat: no-repeat; /* Evita la repetición de la imagen */
    color: white;
    border: 2px solid #ffffff;
    position: relative;
    text-align:center;
}

    .gang-container h3 {
        font-family: 'Monoton';
        font-size: 30px;
        text-align: center;
    }
    .gang-container .description {
        display: block;
        width: 83%;
        padding: 20px;
        background-color: transparent;
        border: none;
        background-image: url('../src/images/new-gang.png'); /* Ruta a tu imagen de fondo */
        background-size: 100% 100%; /* Ajusta la imagen para cubrir todo el fondo */
        background-position: center; /* Centra la imagen en el fondo */
        background-repeat: no-repeat; /* Evita la repetición de la imagen */
        margin: auto;
        color: black;
        font-family: 'Mean';
        font-size:12px;
    }
    .gang-container .first-buttons {
        width: min(400px, 700px);
        margin: auto;
        display: flex;
        justify-content: space-around; /* Para distribuir los elementos equidistantes */
        align-items: center;
    }
        .gang-container .first-buttons button {
            width: 160px;
            padding: 10px;
            background-color: transparent;
            border: none;
            background-image: url('../src/images/bluebutton.png'); /* Ruta a tu imagen de fondo */
            background-size: 100% 100%; /* Ajusta la imagen para cubrir todo el fondo */
            background-position: center; /* Centra la imagen en el fondo */
            background-repeat: no-repeat; /* Evita la repetición de la imagen */
            color: white;
            transition: transform 0.3s ease, box-shadow 0.3s ease;
            font-family: 'Mean';
            font-size: 12px;
            position: relative;
        }
        .gang-container .first-buttons .notify-gang-image {
            left: auto;
            right: 0px;
        }
        .main-table {
            width: 85%;
            display: grid;
            gap: 10px;
            margin: auto;
            font-family: 'Fjalla';
        }
        .main-table .title-tab{
            margin-top:10px;
        }
    .main-table .ranking-row {
        display: grid;
        grid-template-columns: 1fr 3fr repeat(3, 1fr);
        grid-column-gap: 4px;
        grid-row-gap: 6px;
        max-height: calc(100vh - 400px);
        overflow-y: scroll;
        text-align: center;
        font-family: 'Fjalla';
        font-size: 12px;
    }
.ranking-first div, .ranking-second div, .ranking-third div {
    font-family: 'Fjalla';
    font-size: 12px;
}
        .main-table .tab-row {
            display: grid;
            grid-template-columns: 1fr 3fr repeat(4, 1fr);
            grid-column-gap: 4px;
            grid-row-gap: 6px;
            max-height: calc(100vh - 315px);
            overflow-y: scroll;
            text-align: center;
        }
            
            .main-table .title {
                background-color: white;
                color: black;
                position: relative;
            }
.ranking-table {
    padding-top: 25px;
    position: relative;
}
.ranking-container .hovered-info {
    position: absolute;
    top: -10px; /* Asegura que el div aparezca justo debajo del elemento */
    left: calc(50% - 150px); /* Alinea el div a la izquierda del elemento padre */
    z-index: 1000; /* Asegura que el div se sobreponga a otros elementos */
    background-color: black; /* Fondo blanco para que sea legible */
    color: white;
    border-radius: 10px;
    border: 1px solid #ccc; /* Borde gris claro */
    padding: 10px; /* Añade un poco de espacio alrededor del contenido */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Añade una sombra para dar efecto de sobreposición */
    width: 300px;;
    opacity: 0.7;
    font-size:12px;
    text-align: center;
}
.gang-container .main-table .tab-5 {
    grid-template-columns: 1fr 3fr repeat(3, 1fr);
}
.title-table{
    padding-right:0px;

}
.search-table .title-table {
    margin-bottom: 6px;
}
.sol-table .title-table {
    margin-bottom: 6px;
}
.main-table  .pos {
    background-color: white;
    color: black;
}
.main-table .item {
    background-color: transparent;
    border: solid 2px #ffffff;
    color: white;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    min-height: 22px;
    align-content: center;
}
.main-table  .exp button {
    background-color: transparent;
    border: none;
    color: white;
    width:100%;
    height:100%;
}
    .main-table  .exp button:hover {
        background-color: rgba(0, 0, 0, 0.2);
        border: solid 2px #ffffff;
    }
.second-buttons {
    width: min(600px, 600px);
    margin: auto;
    display: flex;
    justify-content: space-around; /* Para distribuir los elementos equidistantes */
    align-items: center;
    position:absolute;
    bottom:30px;
    z-index: 2;
    left: 50%;
    transform: translateX(-50%);
}

    .second-buttons button {
        width: 160px;
        padding: 30px;
        background-color: transparent;
        border: none;
        background-image: url('../src/images/bluebutton.png'); /* Ruta a tu imagen de fondo */
        background-size: 100% 100%; /* Ajusta la imagen para cubrir todo el fondo */
        background-position: center; /* Centra la imagen en el fondo */
        background-repeat: no-repeat; /* Evita la repetición de la imagen */
        color: white;
        transition: transform 0.3s ease, box-shadow 0.3s ease;
        font-family: 'Mean';
        filter: opacity(0.7);
    }
    .second-buttons .selected-button {
        filter: opacity(1);
    }
    .table-buttons{
       width:60px;
       position: absolute;
       left: 20px;
       top: 230px;
   }
 .table-buttons button {
    width: 100%;
    background-color: transparent;
    border: none;
}
     .table-buttons button img{
        width: 100%;
    }
     .table-buttons .next {
        transform: rotate(180deg);
        margin-top:250px;
    }

.gang-container .sol-table{
    width:80%;
    margin:auto;
    border: solid 2px white;
    padding: 30px 10px 30px 10px;
}
    .gang-container .sol-table .tab-row {
        display: grid;
        grid-template-columns: 2fr repeat(3, 1fr);
        grid-column-gap: 4px;
        grid-row-gap: 6px;
        max-height: calc(100vh - 240px);
        overflow-y: auto;
        text-align: center;
    }
.gang-container .sol-table .title{
    border: solid 2px #ffffff;
    color: white;
}
    .gang-container .sol-table .item {
        border: solid 2px #000000;
        color: black;
        background-color: #ffffff;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
.gang-container .sol-table .exp {
    border: none;
    color: white;
    background-color: transparent;
}
.gang-container .sol-table .exp button {
    max-height:100%;
    background-color:transparent;
    border: none;
    color: white;
}
    .gang-container .sol-table .exp button img{
        max-height: 30px;;
    }


.ranking-container .main-table{
    width:90%;

}
.ranking-container .table-buttons {
    width: 60px;
    position: absolute;
    left: 46px;
    top: 282px;
}
.ranking-container .main-table-ranking-gangs{
    padding-top:25px;
    position: relative;

}
.gang-container .search-table {
    width: 80%;
    margin: auto;
    border: solid 2px white;
    padding: 30px 10px 30px 10px;
}

    .gang-container .search-table .tab-row {
        display: grid;
        grid-template-columns: 2fr repeat(2, 1fr);
        grid-column-gap: 4px;
        grid-row-gap: 6px;
        max-height: calc(100vh - 325px);
        overflow-y: auto;
        text-align:center;
    }
    .gang-container .invitar .tab-row {
        display: grid;
        grid-template-columns: 2fr repeat(2, 1fr);
        grid-column-gap: 4px;
        grid-row-gap: 6px;
        max-height: calc(100vh - 410px);
        overflow-y: auto;
        text-align: center;
    }
    .gang-container .search-table .title {
        border: solid 2px #ffffff;
        color: white;
    }
.tab-row .disabled-class {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.6;
}

.gang-container .search-table .item {
    border: solid 2px #000000;
    color: black;
    background-color: #ffffff;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

    .gang-container .search-table .exp {
        border: none;
        color: white;
        background-color: transparent;
    }

        .gang-container .search-table .exp button {
            max-height: 100%;
            background-color: transparent;
            border: none;
            color: white;
        }

            .gang-container .search-table .exp button img {
                max-height: 30px;
                ;
            }
    .gang-container .search-table .input-row {
        display: grid;
        grid-template-columns: 2fr repeat(1, 1fr);
        grid-template-rows: repeat(1, 1fr);
        grid-column-gap: 4px;
        grid-row-gap: 6px;
        padding:20px;
        border: solid 2px #ffffff;
        margin-bottom:20px;
    }
    .gang-container .search-table .input-row button {
        background-color: transparent;
        border: none;
        background-image: url('../src/images/bluebutton.png'); /* Ruta a tu imagen de fondo */
        background-size: 100% 100%; /* Ajusta la imagen para cubrir todo el fondo */
        background-position: center; /* Centra la imagen en el fondo */
        background-repeat: no-repeat; /* Evita la repetición de la imagen */
        color: white;
        font-family: 'Mean';
        font-size: 18px;
        padding:10px;
    }



.choose-wine-container {
    width: 100%;
    max-height: 80%;
    display: flex;
    justify-content: space-around;
}
    .choose-wine-container .image-link {
        height: 400px;
        transition: transform 0.2s ease;
    }
    .choose-wine-container .image-link:hover {
        transform: scale(1.1);
    }
    .choose-wine-container .image-link img{
        height: 90%;
    }
        .choose-wine-container .image-link span {
            display: block;
            color: black;
            font-size: 30px;
            text-align: center;
        }

.choose-wine-container .disabled{
  opacity: 0.5; /* Hace que la imagen parezca más opaca */
  pointer-events: none; /* Desactiva los eventos de clic */
}
@media (max-height: 760px) {
    .choose-wine-container .image-link {
        height: 350px;
    }
    .slider-container h2 {
        margin-bottom: 12px;
        font-size: 35px;
    }
    .btn-test-container {
        bottom: 10px;
        padding-right: 50px;
        padding-left: 50px;
    }
    .btn-back, .btn-next {
        height: 85px;
        width: 45px;
        font-size: 10px;
    }
    .btn-second-test-container {
        bottom: 15px;
        padding-right: 0px;
        padding-left: 0px;
    }
    .btn-inst, .btn-help {
        background-size: 60px;
        height: 80px;
        width: 100px;
        font-size: 10px;
    }
}
@media (max-height: 660px) {
    .slider-container .section-container {
        padding-top: 15px;
    }
        .slider-container .section-container .section-title {
            top: -15px;
            font-size: 13px;
            min-width:190px;
        }
    .slider-container .sub-section-container .section-title{
        font-size:12px;
    }
    .section-container .btn {
        min-width: 90px;
        height: 30px;
        font-size: 11px;
    }
    .slider-container .sub-section-container button {
        font-size: 11px;
    }
}

@media (max-height: 580px) {
    .global-buttons-bh {
        padding-left: 2px;
        padding-right: 2px;
    }
        .global-buttons-bh button {
            height: 60px;
        }
}


@media (max-height: 770px) {
    .stats-first {
        height: 100%
    }

    .stats-content {
        height: 90vh;
    }
    .stats-second {
        height: 100%
    }
        .stats-second .wines {
            padding: 10px;
            padding-left: 40px;
        }
    .stats-first .chart-container {
        max-height: calc(100vh - 140px);
    }
}
@media (max-width: 880px) {
    .stats-second .wine-img {
        height: 150px;
    }
    
}

@media (max-height: 685px) {
    .stats-second .wine-img {
        height: 150px;
    }
}
@media (max-height: 570px) {
    .stats-second .wine-img {
        height: 120px;
    }
}
@media (max-height: 580px) {
    .choose-wine-container .image-link {
        height: 210px;
    }

    .slider-container h2 {
        margin-bottom: 12px;
        font-size: 30px;
    }
    .slider-container .cont-sub-section {
        display: flex;
        margin-top: 15px;
    }


        .slider-container .section-container .section-title {
            top: -15px;
            font-size: 11px;
            min-width: 190px;
        }
    .slider-container .sub-section-container .section-title {
        font-size: 11px;
    }
    .section-container .btn {
        min-width: 80px;
        height: 25px;
        font-size: 9px;
    }
    .slider-container .sub-section-container button {
        font-size: 9px;
        padding:2px;
    }
}
@media (max-height: 500px) {
    .btn-back, .btn-next {
        height: 55px;
        width: 10px;
        font-size: 10px;
    }
    .btn-inst, .btn-help {
        background-size: 35px;
        height: 55px;
        width: 110px;
        font-size: 9px;
    }
}





@media (max-width: 1050px) {
    .line {
        width: 190px;
        margin: 5px;
        padding-left: 4px;
        padding-top: 2px;
    }
        .line .line-image {
            height: 23px;
        }
    .input-slider-container .css-yvszuv-Slider {
        width: 90px;
        height:6px;
    }
    .input-slider-container .input-info {
        font-size: 8px;
        height: 20px;
    }
}


@media (max-width: 920px), (max-height: 600px){
    .sliders-container .taste-chart-container {
        max-width: 250px;
    }
    .sliders-container .line-d {
        top: 250px;
    }
    .sliders-container .line-c {
        top: 162px;
        right: 2px;
    }
    .sliders-container .line-e {
        top: 162px;
    }
    .sliders-container .line-a {
        top: 0px;
    }
}
@media (max-width: 820px), (max-height: 470px) {
    .sliders-container .taste-chart-container {
        max-width: 200px;
    }

    .sliders-container .line-d {
        top: 200px;
    }

    .sliders-container .line-c {
        top: 122px;
        right: 2px;
    }

    .sliders-container .line-e {
        top: 122px;
    }

    .sliders-container .line-a {
        top: 0px;
    }
}

@media (max-width: 1000px), (max-height: 670px) {
    .text-note .section-title {
        font-size: 13px;
    }
    .slider-container .text-note {
        padding: 10px;
        padding-top: 25px;
        font-size: 11px;
    }
    .note-select-container h4 {
        font-size: 13px;
    }
    .slider-container select {
        font-size: 11px;
    }
    .input-price-container p{
        font-size:11px;
        margin-bottom: 0px;
    }
}
@media (max-width: 885px), (max-height: 475px) {
    .note-chart-container {
        height: 80px;
    }
}
@media (max-width: 825px), (max-height: 415px) {
    .text-note .section-title {
        font-size: 12px;
    }

    .slider-container .text-note {
        font-size: 9px;
    }

    .note-select-container h4 {
        font-size: 12px;
    }

    .slider-container select {
        font-size: 10px;
    }

    .input-price-container p {
        font-size: 12px;
        margin-bottom: 0px;
    }
}

@media (max-height: 490px) {
    .wine-container .wine-bottle {
        border: none;
        filter: drop-shadow(7px 7px 10px #000000);
        background-size: 100% 100%;
        width: 50px;
        height: 180px;
        background-color: transparent;
        display: inline-block;
        margin-left: 17px;
        margin-right: 17px;
    }
    .wine-container {
        min-height: 250px;
    }
        .wine-container .section-title {
            font-size: 14px;
        }
    .slider-container .delete-button {
        font-size: 30px;
    }
}




/*RANKING*/

@media (max-height: 770px) {
    .second-buttons {
        width: min(600px, 600px);
        margin: auto;
        display: flex;
        justify-content: space-around;
        align-items: center;
        position: absolute;
        bottom: 0px;
        z-index: 2;
        left: 50%;
        transform: translateX(-50%);
    }
    .ranking-second, .ranking-third {
        padding-top: 25px;
    }
    .ranking-container {
        padding-top: 20px;
    }
    .ranking-first img, .ranking-second img, .ranking-third img {
        max-width: 40%;
    }
    .ranking-first div, .ranking-second div, .ranking-third div {
        font-size: 12px;
    }
    
}
@media (max-height: 675px) {
    .main-table .ranking-row {
        max-height: calc(100vh - 340px);
    }
    .second-buttons button {
        width: 130px;
        padding: 20px;
        font-size: 13px;
    }
    ranking-container .main-table-ranking-gangs {
        padding-top: 5px;
    }
}
@media (max-height: 580px) {
    .ranking-second, .ranking-third {
        padding-top: 0px;
    }

    .ranking-container {
        padding-top: 0px;
    }
}
@media (max-height: 480px) {
    .ranking-first img, .ranking-second img, .ranking-third img {
        max-width: 20%;
    }
    .ranking-container .main-table-ranking-gangs {
        padding-top: 0px;
    }
}


@media (max-width: 1000px), (max-height: 565px) {
    .gang-button-container {
        top: 170px;
        padding-left: 20px;
        padding-right: 20px;
    }
        .gang-button-container .gang-button {
            width: 230px;
            padding: 20px;
            margin-top: 20px;
        }
    .gang-second-button-container {
        bottom: 5px;
        padding-left: 35px;
        padding-right: 35px;
    }
        .gang-second-button-container .gang-second-button {
            z-index: 2;
        }
}
@media (max-height: 415px) {
    .gang-second-button-container {
        top: 340px;
    }
}

@media (max-width: 1080px) {
    .user-container {
        width: 550px;
    }

    .profile-info {
        bottom: 20px;
    }
    .user-container .gender-buttons {
        left: -140px;
    }
    .user-container .gender-buttons button {
        height: 80px;
        width: 50px;
    }
        .user-container .gender-buttons button img {
            width: auto; /* Ajusta automáticamente el ancho de la imagen */
            height: 100%; /* Hace que la imagen ocupe el 100% del alto del contenedor */
            object-fit: cover;
        }
    .button-edit {
        padding: 25px;
        font-size: 13px;
        width:160px;
        right:-160px;
    }
    .button-logout {
        font-size: 13px;
        width: 160px;
        left: calc(50% - 80px);
        padding: 25px;
        top: 0px ;
        
    }
    .user-container .button-left {
        padding: 25px;
        font-size: 13px;
        min-width:160px;
        left:-160px;
    }

    .button-back {
        max-width: 110px;
    }

    .button-next {
        max-width: 110px;
    }
    .informacion{
        padding: 20px;
        width:775px;
    }
    .button-close {
        width: 42px;
        height: 42px;
    }
    .form-line label {
        font-size: 14px;
    }





}






@media (max-width: 900px) {
        .button-back {
            left: 0px;
            top: 40vh;
        }

        .button-next {
            right: 0px;
            top: 40vh;
        }

        .user-container .gender-buttons {
            left: 0px;
            top: 75px;
        }

        .button-edit {
            right: 0px;
            top: 0px;
        }

        .user-container .button-left {
            left: 0px;
            top: 0px;
        }
        .informacion {
            bottom:40px;
        }
}


    @media (max-width: 850px) {
        .user-container {
            width: 100%;
            overflow: hidden;
        }
        .test-container {
            padding-left: 5px;
            padding-right: 5px;
            align-items: start;
        }

        .slider-container {
            height: 92vh;
            padding: 30px;
        }

        .icon-test {
            height: 40px;
            top: 30px;
            right: 20px;
        }

        .slide-container .note-icon-test {
            height: 30px;
        }

        .actual-wine {
            top: 30px;
            left: 18px;
            font-size: 30px;
        }

        .slider-container .section-container .section-title {
            width: 90%;
        }

        .slider-container .section-container {
            min-width: 100%;
            flex-direction: column;
            align-items: center;
        }

        .slider-container .cont-sub-section {
            flex-direction: column;
            align-items: center;
        }

        .slider-container .col-sub-section {
            min-width: 95%;
            margin-bottom: 20px;
        }

        .overlay .centered-image {
            width: 100vw;
        }

        .btn-test-container {
            position: static;
            width: 100%;
            padding-right: 0px;
            padding-left: 0px;
            margin-top: 15px;
        }

        .btn-second-test-container {
            position: static;
            transform: none;
            padding-right: 0px;
            padding-left: 0px;
            margin: auto;
            margin-top: -90px;
            width: 200px;
        }

        .btn-back, .btn-next {
            height: 85px;
            width: 45px;
            font-size: 10px;
        }

        .btn-second-test-container {
            bottom: 15px;
            padding-right: 0px;
            padding-left: 0px;
        }

        .btn-inst, .btn-help {
            background-size: 60px;
            height: 80px;
            width: 100px;
            min-width: 100px;
            font-size: 10px;
        }

        .sliders-container {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

            .sliders-container .line-d {
                transform: none;
            }

        .line {
            position: static;
            width: 260px;
            margin: 5px;
            padding-right: 13px;
            padding-left: 9px;
            padding-top: 6px;
        }

            .line .line-image {
                height: 31px;
            }

        .sliders-container .taste-chart-container {
            position: static;
            max-width: 300px;
            margin-top: 30px;
            margin-bottom: 30px;
            transform: none;
        }

        .input-slider-container .css-yvszuv-Slider {
            width: 130px;
            height: 7px;
        }

        .slider-container .col-sub-section-2 {
            max-width: 95%;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
        }

        .slider-container .wine-container {
            max-width: 95%;
            margin-top: 20px;
            display: grid;
            grid-template-columns: repeat(4, 25%);
            justify-items: center;
        }

        .slider-container .text-note {
            font-size: 11px;
        }

        .text-note .section-title {
            font-size: 15px;
        }

        .note-chart-container {
            height: 170px;
        }

        .wine-container .wine-bottle {
            width: 50px;
            height: 190px;
            margin-left: 7px;
            margin-right: 7px;
        }

        .slide-container {
            overflow-y: auto;
        }
            /* Para Chrome, Safari y Opera */
            .slide-container::-webkit-scrollbar {
                display: none; /* Oculta la scrollbar sin quitar la funcionalidad de desplazamiento */
            }

        /* Para Firefox */
        .slide-container {
            scrollbar-width: none; /* Oculta la scrollbar sin quitar la funcionalidad de desplazamiento */
        }

        /* Para IE y Edge */
        .slide-container {
            -ms-overflow-style: none; /* Oculta la scrollbar sin quitar la funcionalidad de desplazamiento */
        }

        .slider-container .section-container {
            background-image: url('../src/images/square-section-container.png');
        }
    }
@media (max-width: 470px) {

    .icon-test {
        height: 30px;
        top: 22px;
        right: 14px;
    }

    .slide-container .note-icon-test {
        height: 20px;
    }

    .actual-wine {
        top: 20px;
        left: 18px;
        font-size: 30px;
    }
    .slider-container .section-container .section-title {
        width: 100%;
    }
}
@media (max-width: 360px) {

    .icon-test {
        top: 20px;
        right: 5px;
    }

    .actual-wine {
        top: 20px;
        left: 10px;
        font-size: 30px;
    }
    .slider-container .section-container .section-title {
        font-size: 14px;
    }


}

    @media (max-width: 1250px) {
        .global-buttons-bh button {
            height: 40px;
            width: 40px;
            padding: 0px;
        }
        .stats-content {
            left: 50%;
            top: 0px;
            transform: translate(-50%, -0%);
        }
    }

    @media (max-width: 700px) {
        .home .button-container {
            flex-direction: column;
            bottom: 20px;
        }

        .home-top {
            top: 130px;
            padding: 0px;
            display: flex;
            justify-content: center;
            width: 100%;
        }

        .user-info {
            top: 0px;
            left: 20px;
        }
    }

    @media (max-width: 767px) {
        .stats-content {
            height: calc(100vh - 70px);
            top: auto;
            left: auto;
            transform: none;
        }

        .stats-first .chart-container canvas {
            margin: auto;
        }

        .stats-first {
            height: 700px;
        }
    }

    @media (max-width: 720px) {
        .stats-first {
            height: 93vw;
        }
    }
    @media (max-width: 990px) {
        .chart-container .chart-img {
            width: 40px;
        }

        .variedad .chart-img {
            height: 40px;
        }

        .region .chart-img {
            height: 40px;
        }
    }
@media (max-width: 920px) {
    .chart-container .variedad {
        right: 14vw;
    }
    .chart-container .pais {
        left: 14vw;
    }
}
    @media (max-width: 660px) {
        

        .chart-container .visual {
            top: 30px;
        }

        .chart-container .variedad {
            right: 22vw;
        }

        .chart-container .pais {
            left: 22vw;
        }

        .chart-img .toltip {
            transform: translateX(-10px);
            font-size: 12px;
        }
    }

    @media (max-width: 550px) {
        .stats-first {
            height: 110vw;
        }

            .stats-first .chart-container {
                padding-right: 30px;
                padding-left: 30px;
            }

        .chart-container .olfativa {
            right: 5vw;
            top: 18vw;
        }

        .chart-container .precio {
            left: 5vw;
            top: 18vw;
        }

        .chart-container .gusto {
            right: 0vw;
        }

        .chart-container .region {
            left: -1vw;
        }

        .chart-container .visual {
            top: 30px;
        }

        .chart-container .variedad {
            right: 17vw;
        }

        .chart-container .pais {
            left: 17vw;
        }

        .overlay .dekstop {
            display: none;
        }

        .overlay .mobile {
            display: block;
        }
    }

    @media (max-width: 460px) {
        .stats-first {
            padding-right: 20px;
            padding-left: 20px;
        }

        .stats-first {
            height: 120vw;
        }

            .stats-first .first-buttons button {
                width: 120px;
                padding: 10px;
                font-size: 14px;
            }

            .stats-first .dropdowns-container .stats-dropdown {
                padding: 0px 10px 0px 10px;
                font-size: 13px;
            }
    }

    @media (max-width: 390px) {
        .stats-first .first-buttons {
            justify-content: space-between;
        }

        .stats-first .dropdowns-container {
            justify-content: space-between;
            margin-top: -25px;
        }

            .stats-first .dropdowns-container .stats-dropdown {
                padding: 0px 0px 0px 0px;
                z-index: 1;
            }

        .chart-container .chart-img {
            width: 30px;
        }

        .variedad .chart-img {
            height: 30px;
        }

        .region .chart-img {
            height: 30px;
        }

        .chart-container .precio {
            top: 23%;
            left: 6vw;
        }

        .chart-container .olfativa {
            top: 23%;
            right: 6vw;
        }

        .chart-container .visual {
            top: 40px;
        }

        .chart-container .variedad {
            right: 20vw;
        }

        .chart-container .pais {
            left: 20vw;
        }

        .stats-second .wine-img {
            height: 160px;
        }

        .blur {
            padding: 13px;
        }
        .stats-content .stats-close {
            width: 30px;
            height: 30px;
        }
    }


    @media (max-width: 770px) {

        .ranking-container {
            width: auto;
            padding: 0px;
        }

            .ranking-container .row {
                width: auto;
                padding-right: 0px;
                display: flex;
                justify-content: center;
                margin: auto;
            }

                .ranking-container .row .col-sm-4 {
                    width: 30%;
                }

        .ranking-buttons {
            top: auto;
            bottom: 45px;
            left: 50%;
            width: auto;
            transform: none;
            transform: translateX(-50%);
            z-index: 2;
            padding: 2px;
            display: flex;
            justify-content: center;
            border-radius: 17.5px;
        }

        .rank-indi {
            border-radius: 15px;
        }

        .ranking-buttons button {
            width: calc(50% - 2px);
        }

        .second-buttons {
            width: 100%;
            bottom: 43px;
        }

            .second-buttons button {
                width: 140px;
                padding: 20px;
                font-size: 12px;
            }

        .gang-container {
            width: 100%;
        }

        .container {
            --bs-gutter-x: 0px;
        }

        .global-buttons-bh button {
            height: 40px;
            width: 40px;
        }

        .user-container .button-left {
            left: 0px;
            min-width: 130px;
            top: 3vh;
            padding: 20px;
            font-size: 11px;
        }

        .button-edit {
            right: 0px;
            min-width: 130px;
            width: 130px;
            top: 3vh;
            padding: 20px;
            font-size: 11px;
        }
        .button-logout {
            right: 0px;
            min-width: 130px;
            width: 130px;
            top: calc(3vh + 50px);
            padding: 20px;
            font-size: 11px;
            left: auto;
            right: 0px;
        }
        .profile-info {
            max-width: 90%;
            font-size: 12px;
            bottom: 50px;
            height: 150px;
            padding-left: 12px;
            padding-right: 12px;
        }

        .gang-container .first-buttons {
            width: auto;
        }

        .main-table .tab-row {
            max-height: calc(100vh - 355px);
        }

        .gang-container .description {
            font-size: 11px;
        }

        .slide-container {
            height: calc(100vh - 90px);
        }


        .form-line {
            background-image: url('../src/images/test-section-container.png');
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 15px;
        }

        .gang-container .main-table {
            font-size: 12px;
        }

        .gang-container .tab-row {
            max-height: calc(100vh - 345px);
            overflow-y: auto;
        }

        .gang-container .sol-table .tab-row {
            max-height: calc(100vh - 255px);
        }

        .gang-container .search-table .input-row {
            padding: 5px;
        }

        .search-table .title-table {
            margin-bottom: 6px;
        }

        .sol-table .title-table {
            margin-bottom: 6px;
        }

        .gang-container .search-table .input-row button {
            font-size: 12px;
            padding: 5px;
        }

        .gang-container .search-table .exp button img {
            max-height: 20px;
        }

        .gang-container .sol-table .exp button img {
            max-height: 20px;
        }

        .gang-container .tab-row {
            font-size: 12px;
        }

        .gang-container .sol-table {
            width: 100%;
        }

        .gang-container .search-table {
            width: 100%;
            margin: auto;
            border: solid 2px white;
            padding: 10px 10px 30px 10px;
            margin-top: 20px;
        }

        .form-line input {
            width: 100%;
        }

        .form-line label {
            width: 100%;
            font-size: 13px;
        }
        .main-table .ranking-row {
            max-height: calc(100vh - 420px);
        }
    }
@media (max-width: 600px) {
    .main-table .ranking-row {
        max-height: calc(100vh - 395px);
    }
}
@media (max-width: 450px) {
    .main-table .ranking-row {
        max-height: calc(100vh - 330px);
    }
}
    @media (max-width: 770px) {
        .user-container .gender-buttons {
            left: 0px;
            top: 75px;
        }

            .user-container .gender-buttons button {
                height: 60px;
                width: 40px;
            }

                .user-container .gender-buttons button img {
                    width: auto;
                    height: 100%;
                    object-fit: cover;
                }

        .button-back {
            left: 0px;
            top: 27vh;
            max-width: 90px;
        }

        .button-next {
            right: 0px;
            top: 27vh;
            max-width: 90px;
        }

        .informacion {
            width: 100vw;
            top: auto;
            left: 50%;
            font-size: 12px;
        }

        .button-close {
            width: 35px;
            height: 35px;
        }
    }




    @media (max-width: 500px) {
        .choose-wine-container .image-link {
            height: 300px;
        }

        .gang-container .main-table {
            width: 100%;
        }

        .gang-button-container {
            top: 120px;
        }

            .gang-button-container .gang-button {
                width: 220px;
                padding: 10px;
                margin-top: 10px;
            }

        .gang-second-button-container {
            top: auto;
            bottom: 30px;
        }

            .gang-second-button-container .gang-second-button {
                padding: 10px;
            }

        .user-text {
            width: 65vw;
            bottom: 27px;
            font-size: 14px;
            left: 78px;
        }

        .user-text {
            padding: 10px 10px 10px 50px;
        }

        .register-container {
            padding: 30px;
            padding-bottom: 60px;
        }
    }

    @media (max-width: 710px) and (max-height: 520px) {
        .home button {
            font-size: 18px;
            padding: 10px;
            height: 45px;
        }
    }

    @media (max-width: 290px) {
        .user-image {
            width: 90px;
            height: 90px;
            border-radius: 45px;
        }

        .user-text {
            bottom: 17px;
            font-size: 12px;
            left: 50px;
        }

        .home-top {
            top: 100px;
        }
    }

    @media (max-width: 380px) and (max-height: 640px) {
        .btn-back, .btn-next {
            height: 65px;
            width: 35px;
            font-size: 8px;
        }

        .btn-inst, .btn-help {
            background-size: 40px;
            height: 60px;
            font-size: 8px;
        }

        .btn-second-test-container {
            margin-top: -73px;
        }
    }

    @media (max-width: 340px) {
        .actual-wine {
            top: 18px;
            left: 16px;
            font-size: 20px;
        }

        .icon-test {
            height: 30px;
            top: 18px;
            right: 15px;
        }

        .line {
            width: 100%;
        }

        .input-slider-container .css-yvszuv-Slider {
            width: calc(100vw - 200px);
        }

        .slider-container select {
            width: 100%;
        }


        .global-buttons-bh button {
            height: 30px;
            width: 30px;
        }

        .stats-content {
            height: calc(100vh - 50px);
        }

        .choose-wine-container .image-link {
            height: 230px;
        }

        .register-container h2 {
            margin-bottom: 20px;
            font-size: 30px;
            color: white;
            font-family: Bangers;
            text-shadow: 4px 4px 4px rgba(80, 33, 63, 0.5), 4px -4px 4px rgba(80, 33, 63, 0.5), -4px 4px 4px rgba(80, 33, 63, 0.5);
        }

        .informacion {
            bottom: 30px;
        }
    }

    @media (max-width: 380px) {
        .sliders-container .taste-chart-container {
            max-width: 100%;
            margin-top: 20px;
            margin-bottom: 20px;
        }

        .slide-container .icon-dekstop {
            display: none;
        }

        .slide-container .icon-mobile {
            display: block;
            height: 70px;
            top: 22px;
            right: 10px;
        }
    }

    @media (max-width: 290px) {
        .btn-second-test-container .btn span {
            display: none;
        }

        .btn-inst, .btn-help {
            width: 60px;
            min-width: 60px;
        }

        .btn-second-test-container {
            justify-content: center;
            width: 120px;
        }
    }

    @media (max-width: 500px) and (max-height: 540px) {
        .gang-button-container .gang-button {
            width: 220px;
            padding: 5px;
            margin-top: 5px;
        }

        .gang-second-button-container .gang-second-button {
            padding: 10px;
            margin-top: -5px;
        }
    }

    .tab-row {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

        .tab-row::-webkit-scrollbar {
            display: none;
        }




.custom-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(5px);
    z-index: 1000;
    font-family: 'Fjalla';
}

.custom-popup {
    background-image: url('../src/images/fond.png');
    background-size: 100% 100%; /* Ajusta la imagen para cubrir todo el fondo */
    background-position: center; /* Centra la imagen en el fondo */
    background-repeat: no-repeat;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1001;
    max-width: 500px;
    text-align: center;
    font-family: 'Fjalla';
    position: relative;
}
.custom-overlay .popup-noback{
    background-image: none;
}

    .custom-popup p {
        margin-bottom: 20px;
    }

    .custom-popup button {
        margin: 5px;
        padding: 10px 20px;
        border: none;
        background-color: #007bff;
        color: white;
        border-radius: 5px;
        cursor: pointer;
    }

        .custom-popup button:hover {
            background-color: #0056b3;
        }
    .custom-popup .image-container {
        max-height: 90vh;
        margin-top: 5px;
        margin-bottom: 25px;
        overflow-y: scroll; /* Habilita el scroll vertical */
        overflow-x: hidden;
    }
    .custom-popup .image-container img{
        width:100%;
    }
.profile-info .trofeos {
    background-image: url('../src/images/trofeo.png');
    background-size: 100% 100%; /* Ajusta la imagen para cubrir todo el fondo */
    background-position: center; /* Centra la imagen en el fondo */
    background-repeat: no-repeat;
    width: 80px;
    height: 80px;
    position: absolute;
    bottom: 7px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    color: black;
    line-height: 60px;
    font-size: 20px;;

}
.profile-info .trofeos img{
    width: 80px;
}

.slider-container .stats-close {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 0px;
    right: 0px;
    background-color: transparent;
    border: none;
    padding: 0;
    z-index: 10;
}

.slider-container .stats-close img {
    width: 100%;
    height: 100%;
}
.custom-popup .stats-close {
    width: 40px;
    height: 40px;
    position: absolute;
    top: -20px;
    right: -10px;
    background-color: transparent;
    border: none;
    padding: 0;
    z-index:10;
}
.popup-noback .stats-close {
    top: 0px;
    right: 0px;
}
    .custom-popup .stats-close img {
        width: 100%;
        height: 100%;
    }














.choose-wine-container .image-link .answer-points {
    font-size: 18px;
}

.btn-global-help {
    position: absolute;
    width: 50%;
    bottom: 20px;
    left: 80%;
    transform: translateX(-50%);
    display: flex;
    justify-content: space-between;
    padding-right: 20px;
    padding-left: 20px;
    z-index: 4;
}

.buy-container {
    display: block;
    font-family: Fjalla;
    display:flex;
    flex-direction:column;
    width:180px;
    align-items: center;
    position: absolute;
    top:35px;
    left: 20px;

}
    .buy-container span{
        display: block;
        font-size: 12px;
    }
    .buy-button {
        position: relative;
        width: 160px;
        padding: 20px;
        background-color: transparent;
        border: none;
        background-image: url('../src/images/bluebutton.png');
        background-size: 100% 100%;
        background-position: center;
        background-repeat: no-repeat;
        color: white;
        font-size: 15px;
        transition: transform 0.3s ease, box-shadow 0.3s ease;
    }
    .buy-button:hover {
        transform: scale(1.1);
        box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
        z-index: 1;
    }
@media (max-height: 760px) {
    .buy-container {
        top: auto;
        bottom: 20px;
        left: 10%;
        transform: translateX(0%);
    }
}
@media (max-width: 850px) {
    .buy-container {
        top: auto;
        bottom: 50px;
        left: 50%;
        transform: translateX(-50%);
    }
}